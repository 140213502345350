import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';

import ArrowRightBlue from '../../../img/services/arrow-right-blue.png';
import useScrollAnimation from '../../../utils/use-scrollanimation2';
// import interpolateAndClamp from '../../../utils/interpolate-and-clamp';
// import getClientOffset from '../../../utils/get-client-offset';
const Wrapper = styled.div`
  width: 100%;
  height: 4000px;
  background-color: #01426a;
  .sticky {
    background-color: white;
    position: sticky;
    min-height: 649px;
    height: calc(100vh - 110px);
    top: 110px;
    z-index: 3;
    background-repeat: no-repeat;
    background-size: cover;
    .fixed-wrapper {
      padding-right: 40px;
      padding-left: 40px;
      width: calc(100% - 80px);
      position: absolute;
      left: 0;
      overflow: hidden;
      &.heading {
        bottom: 50%;
        margin-bottom: 210px;
      }
      &.stage {
        height: 555px;
        top: 50%;
        margin-top: -260px;
        .inner {
          max-width: 791px;
          width: 100%;
          height: 525px;
          left: 60px;
        }
      }
    }
    .btn-next {
      position: absolute;
      cursor: pointer;
      color: #00a5d9;
      bottom: 0;
      right: 25px;
      padding-left: 22px;
      padding-right: 22px;
      display: block;
      img {
        transform: rotate(90deg);
      }
    }
  }
  .inner {
    z-index: 1;
    margin: 0 auto;
    position: relative;
    h2 {
      font-family: Oswald;
      font-style: normal;
      font-weight: 200;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      text-transform: uppercase;
      color: #585858;
    }
    .item {
      max-width: 791px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;
      transition: all 250ms cubic-bezier(0.39, 0.58, 0.57, 1) 0s;
      transform-origin: left center;

      /* starting position 290 / 791 */
      position: absolute;
      margin-top: -100px;
      width: 100%;
      left: 0%;
      top: 50%;
      &:nth-child(5) {
        .icon {
          margin-bottom: 8%;
        }
      }
      .icon {
        max-width: 200px;
      }
      .copy {
        padding-left: 20px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        .title {
          font-family: Oswald;
          font-style: normal;
          font-weight: 300;
          font-size: 32px;
          line-height: 36px;
          display: flex;
          align-items: center;
          color: #007dba;
          margin-bottom: 10px;
        }
        .text {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 26px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: #585858;
        }
      }
    }
  }

  &.is-mobile {
    position: relative;
    .sticky {
      top: 104px;
      height: 550px;
      min-height: calc(100vh - 110px);
    }
    .sticky .fixed-wrapper.heading {
      bottom: auto;
      top: 0px;
      margin-bottom: 0;
    }
    .sticky .fixed-wrapper.stage {
      padding-right: 10px;
      padding-left: 10px;
      width: 100%;
      box-sizing: border-box;
      .inner {
        left: 40px;
        .item {
          flex-direction: column;
        }
        .copy {
          padding-right: 0;
        }
      }
    }
    .inner .item .icon {
      max-width: 150px;
    }
    @media (min-width: 550px) {
      .sticky .fixed-wrapper.heading {
        top: 90px;
      }
      .sticky .fixed-wrapper.stage {
        margin-top: -158px;
        padding-right: 20px;
        padding-left: 40px;
        width: calc(100% - 60px);
      }
    }
  }
  &.light-text {
    background-color: transparent;
    .sticky {
      /* background-color: transparent; */
    }
    .inner {
      h1 {
        color: white;
      }
      .item .copy {
        background-color: rgba(1, 66, 106, 0.8);

        .title {
          color: #bce0f4;
        }
        .text {
          color: white;
        }
      }
    }
  }
`;

function ScrollRows(props) {
  const [isMobile, setIsMobile] = useState(false);
  const [dispatch] = useScrollAnimation();

  let defaultScale = 0.5;
  let cssDefault = {
    transform: `scale(${defaultScale}) translate(5%, 93%)`,
    opacity: 0,
    zIndex: 1,
  };
  let [itemStyles, setItemStyles] = useState({
    'item-num-1': cssDefault,
    'item-num-2': cssDefault,
    'item-num-3': cssDefault,
    'item-num-4': cssDefault,
    'item-num-5': cssDefault,
    'item-num-6': cssDefault,
  });

  const getRangeNumber = (percentDecimal, min, max) => {
    return min + (max - min) * percentDecimal;
  };

  const animate = (item) => {
    if (isMobile) {
      return;
    }
    if (typeof item.scrollPct !== 'undefined') {
      // let xCurrent = getRangeNumber(item.scrollPct, 5, -24);
      // let yCurrent = getRangeNumber(item.scrollPct, 93, -71);
      let xCurrent = getRangeNumber(item.scrollPct, 5, -24);
      let yCurrent = getRangeNumber(item.scrollPct, 123, -85);

      // //determine scale and opacity as item scrolls to center and out of center
      let opacity = 0;
      let percentOpacity = 0;
      let scale = defaultScale;

      if (item.scrollPct == 1) {
        //done
        opacity = 0;
        scale = defaultScale;
      } else if (item.scrollPct == 0) {
        //starting point
        opacity = 0;
        scale = defaultScale;
      } else if (item.scrollPct < 0.4) {
        percentOpacity = item.scrollPct / 0.4;
        opacity = getRangeNumber(percentOpacity, 0.4, 1);
        scale = getRangeNumber(percentOpacity, defaultScale, 1);
        if (item.id == 'item-num-1') {
          //is last item
          // opacity = 1;
        }
      } else if (item.scrollPct > 0.6) {
        percentOpacity = (0.4 - (item.scrollPct - 0.6)) / 0.4;
        opacity = getRangeNumber(percentOpacity, 0.1, 1);
        scale = getRangeNumber(percentOpacity, defaultScale, 1);

        if (item.id == 'item-num-6') {
          //is last item
          // opacity = 1;
        }
      } else {
        //in the middle
        opacity = 1;
        scale = 1;
      }

      let zIndex = scale * 10;
      if (scale === 1) {
        zIndex = 100;
      }
      let newItemStyles = {
        transform: `scale(${scale}) translate(${xCurrent}%, ${yCurrent}%)`,
        opacity: opacity,
        zIndex: Math.floor(zIndex),
      };
      setItemStyles((prevState) => {
        let newState = {
          ...prevState,
          [item.id]: newItemStyles,
        };
        // console.log(item.id, newState[item.id]);
        return newState;
      });
    }
  };

  const cumulativeOffset = function (element) {
    var top = 0,
      left = 0;
    do {
      top += element.offsetTop || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
    } while (element);

    return {
      top: top,
      left: left,
    };
  };
  const setupAnimations = () => {
    //set an initial value
    // if (isMobile) {
    //   return;
    // }

    let element = document.getElementById('development-process');
    let start = cumulativeOffset(element).top; // from top + padding + extra;
    let part = element.clientHeight / 6;

    props.rows.forEach((row, index) => {
      // if(index != 0) { return; }
      let conf = {
        type: 'registerItem',
        start: start + part * index - 1000,
        end: start + part * (index + 1) + 300,
        id: `item-num-${index + 1}`,
        onUpdate: animate,
      };
      // console.log('conf', conf);

      dispatch(conf);
    });
  };

  useEffect(() => {
    setupAnimations();
  }, [isMobile, dispatch]);

  useEffect(() => {
    function adjustHeight() {
      const vh = window.innerHeight;
      const vw = window.innerWidth;
      setIsMobile((value) => {
        if (vw <= 900) return true;
        else return false;
      });
    }
    window.addEventListener('resize', debounce(adjustHeight, 300));
    adjustHeight();
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  let wrapperClasses = [];
  if (isMobile) {
    wrapperClasses.push('is-mobile');
  }
  if (props.lightText) {
    wrapperClasses.push('light-text');
  }

  return (
    <>
      <Wrapper className={wrapperClasses} id="development-process">
        <div className="sticky" style={{ backgroundImage: props.bgImage ? `url('${props.bgImage}')` : 'none' }}>
          <div className="fixed-wrapper heading">
            <div className="inner">
              <h2>{props.title}</h2>
            </div>
          </div>
          <div className="fixed-wrapper stage">
            <div className="inner">
              {props.rows.map((row, index) => (
                <div
                  className="item"
                  key={index}
                  id={`item-num-${index + 1}`}
                  style={itemStyles[`item-num-${index + 1}`] ? itemStyles[`item-num-${index + 1}`] : {}}
                >
                  <img className="icon" src={row.img.publicURL} />
                  <div className="copy">
                    <div className="title">{row.title}</div>
                    <div className="text">{row.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <span className="btn-next">
            <img
              onClick={(e) => {
                e.preventDefault();
                let element = document.getElementById('next-section');
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
              }}
              src={ArrowRightBlue}
            />
          </span>
        </div>
      </Wrapper>
      <div id="next-section"></div>
    </>
  );
}

export default ScrollRows;
